.scheduleBody {
  padding: 1rem;
}
.scheduleDayContainer {
  list-style-type: none;
  display: grid;
  place-items: center;
}

.flexScheduleContainer {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  flex-wrap: wrap;
}

.scheduleDayContainer a {
  text-decoration: none;
  color: var(--SECONDARYCOLOR);
  background-color: var(--PRIMARYCOLOR);
  width: 160px;
  padding: 0.5rem;
  height: 60px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.scheduleDayContainer a:hover {
  background-color: var(--BUTTONBACKGROUNDCOLOR);
}

.scheduleBody {
  margin-bottom: 3rem;
}

@media screen and (max-width: 320px) {
  .flexScheduleContainer {
    display: grid;
    place-content: center;
  }
}
