.homeBlogsContainer {
  padding: 1rem;
  margin-bottom: 5rem;
}

.homeBlogsHeader {
  margin-bottom: 3rem;
  display: grid;
  place-items: center;
  gap: 1rem;
}

.homeBlogsHeaderImage {
  position: relative;
  display: grid;
  place-items: center;
}

.homeBlogsHeaderImage h2 {
  position: absolute;
  top: 0;
  font-size: 1rem;
  text-transform: uppercase;
  transform: translateX(1.3rem);
  color: var(--SECONDARYCOLOR);
}

.blogsContainer {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.blogItem {
  max-width: 350px;
  min-height: 300px;
  box-shadow: 0px 5px 5px var(--PRIMARYCOLOR);
  padding: 1rem;
}
.holdBlogItem {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
}

.styledatetime {
  border: 1px solid var(--PRIMARYCOLOR);
  font-weight: 900;
  width: 160px;
  display: grid;
  place-items: center;
  border-radius: 5px;
}

.holdBlogItem h3 {
  text-transform: capitalize;
}

.homeBlogsHeader h3 {
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
}

.homeBlogsHeader p {
  text-align: center;
}

.blogLinkHome a {
  text-decoration: none;
  color: var(--SECONDARYCOLOR);
  text-transform: uppercase;
  background-color: var(--PRIMARYCOLOR);
  padding: 0.6rem;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
}

.blogLinkHome svg {
  margin-inline: 0.5rem;
  transform: translateY(0.2rem);
}

.blogLinkHome a:hover {
  background-color: var(--BUTTONBACKGROUNDCOLOR);
}

@media screen and (max-width: 767px) {
  .blogsContainer {
    display: grid;
    place-content: center;
  }
}
