.progressContainer {
  margin-bottom: 5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.clockProgression,
.workProgression,
.nutritionProgression {
  width: 350px;
  height: 300px;
  box-shadow: 0px 5px 5px var(--PRIMARYCOLOR);
  display: grid;
  place-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
  z-index: 1;
  background-color: var(--SECONDARYCOLOR);
  border-radius: 10px;
}

.clockProgression:hover::after,
.workProgression:hover::after,
.nutritionProgression:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../ImageAssets//who-we-are/info-card-bg.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  z-index: -1;
  cursor: pointer;
}

.clockProgression:hover,
.workProgression:hover,
.nutritionProgression:hover {
  background-color: var(--PRIMARYCOLOR);
}

.clockProgression:hover p,
.clockProgression:hover h3,
.workProgression:hover p,
.workProgression:hover h3,
.nutritionProgression:hover p,
.nutritionProgression:hover h3 {
  color: var(--SECONDARYCOLOR);
}

@media screen and (max-width: 960px) {
  .progressContainer {
    display: grid;
    gap: 1rem;
  }
  .clockProgression,
  .nutritionProgression,
  .workProgression {
    width: 100%;
  }
  .clockProgression:hover::after,
  .workProgression:hover::after,
  .nutritionProgression:hover::after {
    background-position: top;
    background-size: contain;
  }
}
