.fitnessTrainerContainer {
  padding: 1rem;
  margin-bottom: 5rem;
  line-height: 4rem;
  min-height: 350px;
  background-color: var(--PRIMARYCOLOR);
  color: var(--SECONDARYCOLOR);
  background-image: url(../../..//ImageAssets/call/background.jpg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.fitnessTrainerContainer h2 {
  font-size: 2rem;
}

.styleCall {
  color: var(--BUTTONBACKGROUNDCOLOR);
}

.fitnessTrainerContainer p {
  font-weight: 900;
  font-size: 2.5rem;
}

.contactLinkHome a {
  background-color: var(--SECONDARYCOLOR);
  color: var(--PRIMARYCOLOR);
  display: grid;
  place-items: center;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  width: 140px;
  height: 60px;
  text-transform: uppercase;
  font-size: 1rem;
}

.contactLinkHome a:hover {
  background-color: var(--BUTTONBACKGROUNDCOLOR);
}
