.nav {
  background-color: var(--PRIMARYCOLOR);
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navLinks {
  display: flex;
  list-style-type: none;
  gap: 1rem;
}

.linkItem,
.signInDiv,
.rotateDiv,
.navHeaderLv5 {
  display: grid;
  place-items: center;
}

.nav a {
  color: var(--SECONDARYCOLOR);
  text-decoration: none;
  font-size: 1rem;
}

.nav a:hover {
  color: var(--BUTTONBACKGROUNDCOLOR);
}
.navBody {
  display: flex;
  gap: 2rem;
}

.linkClass {
  display: flex;
  border: 1px solid var(--SECONDARYCOLOR);
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem;
}

.rotateDiv {
  width: 40px;
  height: 100%;
  background-color: red;
  transition: 0.3s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
}
.rotateDiv:hover {
  transform: rotate(180deg);
}

.rotateDiv a:hover {
  color: white;
}
.navHeaderLv5 {
  text-transform: uppercase;
}
.hamburger {
  display: none;
}

@media screen and (max-width: 1023px) {
  .nav {
    position: relative;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .hamburger {
    display: block;
    width: 32px;
    appearance: none;
    background: none;
    border: none;
    position: relative;
    z-index: 5;
  }
  .hamburger .bar,
  .hamburger::before,
  .hamburger::after {
    content: "";
    width: 100%;
    height: 5px;
    background-color: red;
    margin: 6px 6px;
    transition: 0.4s;
    display: block;
  }
  .hamburger.is-active::before {
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  .hamburger.is-active .bar {
    opacity: 0;
  }
  .hamburger.is-active::after {
    transform: rotate(45deg) translate(-9px, -6px);
  }
  .navLinks {
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    min-height: 100vh;
    padding-top: 120px;
    width: 100%;
    background-color: var(--SECONDARYCOLOR);
    padding-top: 120px;
    line-height: 3rem;
    transition: 0.4s ease-in-out;
    overflow-x: hidden;
    text-align: center;
    z-index: 2;
  }

  .navLinks a {
    color: var(--PRIMARYCOLOR);
    transition: color 0.3s ease-in-out;
    font-size: 1.2rem;
  }
  .navLinks.is-active {
    left: 0;
  }
}

@media screen and (max-width: 570px) {
  .linkClass {
    display: none;
  }
}
