.classPageHeader {
  margin-bottom: 2rem;
}

.classesPageGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Adjust as needed */
  gap: 15px 13px; /* Gap between items */
  margin-bottom: 5rem;
  background-image: url(../../ImageAssets/who-we-are/background.jpg);
  padding: 1rem;
}

.classesPageDiv {
  flex-grow: 1;
  width: 350px;
  height: 400px;
  background-size: cover;
  cursor: pointer;
  background-color: green;
  position: relative;
  margin-bottom: 20px; /* Adjust spacing between rows */
}

.childClassPageDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.childClassPageDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.classGridItemdescription {
  filter: grayscale(0%);
  color: white;
  padding: 1rem;
  line-height: 3rem;
  font-size: 1.3rem;
}

.classesContactLink a {
  text-decoration: none;
  color: var(--PRIMARYCOLOR);
  background-color: var(--SECONDARYCOLOR);
  padding: 0.7rem;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.classesContactLink a:hover {
  background-color: var(--BUTTONBACKGROUNDCOLOR);
}

.classesContactLink svg {
  margin-inline: 0.6rem;
  transform: translateY(0.2rem);
  color: purple;
}

@media screen and (max-width: 846px) {
  .classesPageDiv {
    width: 350px;
  }
}
@media screen and (max-width: 748px) {
  .classesPageDiv {
    width: 300px;
  }
}
@media screen and (max-width: 646px) {
  .classesPageDiv {
    width: 250px;
  }
}
