.gymLogosContainer {
  padding: 1rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

@media screen and (max-width: 500px) {
  .gymLogosContainer {
    display: grid;
    place-content: center;
  }
}
