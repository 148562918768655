@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --PRIMARYCOLOR: black;
  --SECONDARYCOLOR: white;
  --BUTTONBACKGROUNDCOLOR: #ff0336;
  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
  --FONTSIZE: 1.2rem;
}

body {
  font-family: var(--font-family);
  min-height: 100vh;
  width: 100%;
  font-size: var(--FONTSIZE);
}
