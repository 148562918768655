.aboutUsHomeContainer {
  display: flex;
  padding: 1rem;
  background-image: url("../../../ImageAssets//who-we-are/background.jpg");
  background-size: cover;
  flex-wrap: wrap;
  margin-bottom: 10rem;
}

.aboutUsHomeHeader {
  display: grid;
  flex: 1.5;
}

.aboutUsHomeHeaderImage {
  position: relative;
}

.aboutUsHomeHeaderImage h2 {
  text-transform: uppercase;
  font-size: 1.1rem;
  position: absolute;
  top: 0;
  transform: translateX(2.5rem);
  color: var(--SECONDARYCOLOR);
}

.aboutUsHomeHeader h3 {
  font-weight: bolder;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.aboutUsHomeHeader p {
  margin-bottom: 1rem;
}

.aboutUsHomeEquipments {
  display: flex;
  margin-bottom: 3rem;
}

.equipment {
  border-right: 1px solid black;
  width: 200px;
  height: auto;
  display: grid;
  gap: 1rem;
  place-items: center;
  text-align: center;
  padding: 0.5rem;
}

.equipment h2 {
  text-transform: uppercase;
  font-size: 1.1rem;
}

.equipmentImage {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.equipmentImage img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.aboutUsHomeHeader a {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--PRIMARYCOLOR);
}

.aboutUsHomeBtn {
  width: 140px;
  height: 40px;
  display: grid;
  place-items: center;
  background-color: var(--PRIMARYCOLOR);
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.aboutUsHomeBtn:hover {
  background-color: var(--BUTTONBACKGROUNDCOLOR);
}

.aboutUsHomeBtn a {
  color: var(--SECONDARYCOLOR);
}

.aboutUsHomeBody {
  background-image: url("../../../ImageAssets/who-we-are/girl-side-text.png");
  background-repeat: no-repeat;
  background-position: center;
  flex: 2.5;
}

.aboutUsHomeBodyImage {
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
}

.aboutUsHomeBodyImage img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1023px) {
  .aboutUsHomeBody {
    display: none;
  }

  .aboutUsHomeHeader {
    display: grid;
    place-items: center;
  }
}

@media screen and (max-width: 670px) {
  .aboutUsHomeEquipments {
    display: grid;
    gap: 2rem;
  }
  .aboutUsHomeHeader h3,
  .aboutUsHomeHeader p {
    text-align: center;
  }
}
