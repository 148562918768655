.flexScheduleContainer {
  margin-bottom: 5rem;
  padding: 2rem;
}

.dailySchedule {
  width: 100%;
}

.dailyContainer {
  display: flex;
  justify-content: space-between;
  background-color: lightgray;
  gap: 1rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.dailyContainer div {
  display: grid;
  place-items: center;
  font-size: 1rem;
}

.schedulePostPageContactLink {
  display: grid;
  place-items: center;
}

.schedulePostPageContactLink a {
  background-color: green;
  display: grid;
  place-items: center;
  width: 100px;
}

@media screen and (max-width: 767px) {
  .dailyContainer {
    display: grid;
    place-content: center;
  }
}
