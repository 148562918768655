.homeTestimonialContainer {
  padding: 1rem;
  margin-bottom: 5rem;
  display: grid;
  gap: 2rem;
  border: 5px double lightgray;
}

.homeTestimonialHeaderImage {
  position: relative;
  display: grid;
  place-items: center;
}

.homeTestimonialHeaderImage h2 {
  position: absolute;
  top: 0;
  transform: translateX(1.5rem);
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--SECONDARYCOLOR);
}

.homeTestimonialBody {
  display: flex;
  justify-content: flex-start;
}

.homeTestimonialBodyImage {
  display: grid;
  place-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

.homeTestimonialBodyImage img {
  width: 100%;
  height: 100%;
}

.homeTestimonialDescription {
  flex: 1.5;
  background-color: var(--PRIMARYCOLOR);
  color: var(--SECONDARYCOLOR);
  background-image: url(../../../ImageAssets/testimonials/testimonial-shape.svg);
  display: grid;
  place-items: center;
}

.flexTestimonialDescription {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .homeTestimonialBodyImage {
    display: none;
  }
  .flexTestimonialDescription {
    padding: 1rem;
  }
}
